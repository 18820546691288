import React from "react";

import '../styles/skillsStyle.css';
import '../styles/style.css';

class Skills extends React.Component {
    
    render(){
        return(
            <div>
                <section id="skills-section">

                    <div className="row intro-block">
                        <div className="centerfold-wrap">
                            <div className="hex-master-wrap">
                                    <div className="hover-notify"></div>
                                <div className="grid-1">
                                    <div className="hex-wrap backend" data-title="BACKEND" data-content="backend-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-code fa-5x backend-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hex-wrap frontend" data-title="FRONTEND" data-content="frontend-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-laptop fa-5x frontend-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-2">
                                    <div className="hex-wrap html" data-title="EDUCATION" data-content="html-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-graduation-cap fa-5x education-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hex-wrap css">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                            
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hex-wrap jquery" data-title="DESIGN" data-content="javascript-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-paint-brush fa-5x design-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-3">
                                    <div className="hex-wrap gui" data-title="OTHERS" data-content="gui-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-briefcase fa-5x others-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hex-wrap seo" data-title="DATABASE" data-content="seo-desc" data-color="#67c3d0">
                                            <div className="hex-init"></div>
                                            <div className="hex-borders">
                                                <div className="border-1"></div>
                                                <div className="border-2"></div>
                                                <div className="border-3"></div>
                                            </div>
                                            <div className="label">
                                                <i className="fa fa-database fa-5x database-icon"></i>
                                            </div>
                                        <div className="hexagon">
                                            <div className="hex-inner-1">
                                                <div className="hex-inner-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="code-display">
                                <div className="code-description">
                                    <div className="backend-desc">
                                        <p className="code-title"></p>                                    
                                                <p><span>NodeJs</span></p>
                                                <p><span>ExpressJS</span></p>
                                                <p><span>PHP</span></p>
                                                <p><span>Java</span></p>                                   
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="frontend-desc">
                                        <p className="code-title"></p>
                                        <p><span>HTML</span></p>
                                        <p><span>CSS</span></p>
                                        <p><span>JavaScript</span></p>
                                        <p><span>Bootstrap</span></p>
                                        <p><span>ReactJS</span></p>
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="html-desc">
                                        <p className="code-title"></p>
                                        <p><span>2022: MS IT Software Engineering</span></p>
                                        <p><span>2019: BS IT Software Development</span></p>
                                        <p><span>2012: AS Graphic Design</span></p>
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="css-desc">
                                        <p className="code-title"></p>
                                        <p><span>Duration:</span>6 Years</p>
                                        <p><span>Experience</span>CSS3 (most recent)</p>
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="javascript-desc">
                                        <p className="code-title"></p>
                                        <p><span>Photoshop</span></p>
                                        <p><span>Illustrator</span></p>
                                        <p><span>Adobe XD</span></p>
                                        <p><span>Zeplin</span></p>
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="gui-desc">
                                        <p className="code-title"></p>
                                        <p><span>Leadership</span></p>
                                        <p><span>OOP</span></p>
                                        <p><span>UML</span></p>
                                        <p><span>WordPress</span></p>
                                        <p><span>Troublshooting</span></p>
                                        <p><span>Documentation</span></p>
                                        <p><span>SDLC</span></p>
                                        <p><span>AWS EC2, S3, RDS</span></p>
                                        <div className="code-icon"></div>
                                    </div>
                                    <div className="seo-desc">
                                        <p className="code-title"></p>
                                        <p><span>SQL</span></p>
                                        <p><span>NoSQL</span></p>
                                        <p><span>MySQL</span></p>
                                        <p><span>MongoDB</span></p>
                                        <div className="code-icon"></div>
                                    </div>
                                </div>

                            </div>
                                <div className="hoverblock"></div>
                        </div> 
                    </div>

                    </section>
                    <section id = "mobile-skills-section">
                    <div className = "mobile-skills-title">Skills & Education</div>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <div className="accordion-title">FRONTEND</div>
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>HTML</li>
                                    <li>CSS</li>
                                    <li>BOOTSTRAP</li>
                                    <li>REACTJS</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <div className="accordion-title">BACKEND</div>
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>NodeJS</li>
                                    <li>ExpressJS</li>
                                    <li>PHP</li>
                                    <li>JAVA</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                <div className="accordion-title">DESIGN</div>
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>Photoshop</li>
                                    <li>Illustrator</li>
                                    <li>Adobe XD</li>
                                    <li>Zeplin</li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div className="accordion-title">DATABASE</div>
                            </button>
                            </h2>
                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>SQL</li>
                                    <li>NoSQL</li>
                                    <li>MySQL</li>
                                    <li>MongoDB</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div className="accordion-title">OTHERS</div>
                            </button>
                            </h2>
                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>Leadership</li>
                                    <li>OOP</li>
                                    <li>UML</li>
                                    <li>Troubleshooting</li>
                                    <li>Documentation</li>
                                    <li>SDLC</li>
                                    <li>AWS EC2, S3, RDS, IAM</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseOne">
                                <div className="accordion-title">EDUCATION</div>
                            </button>
                            </h2>
                            <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <ul className="skills-edu-list">
                                    <li>2022: MS IT Software Engineering</li>
                                    <li>2019: BS IT Software Development</li>
                                    <li>2012: AS Graphic Design</li>

                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>

                    </section> 
            </div>
        )
    }
}

export default Skills;