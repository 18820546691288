import React from "react";
import '../styles/style.css';

class Projects extends React.Component {
    render(){
        return(
            <div>
                <section id="section-portfolio">
<div className="container">
    <h1 className="portfolio-title">Sample Projects</h1>
    <div className="row project-row ">
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">Love Luna</div>
                <div className="project-item-p">A simple page design derived from tinder!</div>
            </div>
        </div>
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">Resume</div>
                <div className="project-item-p">Resume site created with simple html & css.</div>
            </div>
        </div>
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">QQookery</div>
                <div className="project-item-p">A cooking recipe site created with MERN stack.</div>
            </div>
        </div>
    </div>

    <div className="row project-row">
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">Deliverish</div>
                <div className="project-item-p">Food ordering site created with MERN stack.</div>
            </div>
        </div>
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">Logistix</div>
                <div className="project-item-p">Inventory application using LAMP stack.</div>
            </div>
        </div>
        <div className="project-item col-lg-4 col-md-6 col-sm-12">
            <div className="project-image"><img src="./images/hero1.png" height="160px" width="160px" alt=""/></div>
            <div className="project-item-desc">
                <div className="project-item-title">Milktea Nation</div>
                <div className="project-item-p">Milktea store website created using WordPress & Divi plugin.</div>
            </div>
        </div>
    </div>
</div>
</section>
            </div>
        )
    }
}

export default Projects;
