import React from 'react';
import '../styles/style.css';
import emailjs from 'emailjs-com';
import Recaptcha from 'react-recaptcha';

class Contact extends React.Component {
    constructor(){
        super()
        this.sendEmail = this.sendEmail.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

        this.state = {
            isVerified: false         
        }
        }

    recaptchaLoaded(){
        console.log('capcha is loaded');
    }

    sendEmail(e){
        if(this.state.isVerified){
            e.preventDefault();

            emailjs.sendForm(
                "service_n10dmbs",
                "template_56ilr1p", 
                e.target,
                "user_OAOMkn9fzdMHZMcbaDZwQ"
                ).then((result) => {
                    console.log(result.text);
                    result.text ==="OK" ? document.getElementById('success-notice').innerHTML = "Message sent, thank you very much!" : alert("didnt work")
                    e.target.reset();
                      
                },).catch(err=>console.log(err));                      
        } else {
            e.preventDefault();
            alert('Pls verify you are a human');
            
        }
    }
        verifyCallback(response){

            if(response){
                this.setState({
                    isVerified: true
                })
            }
        }


        render(){
        return(
            <div>
            <section id="section-contact-me">
                <div className="container-fluid">
                    <h1 className="contact-me-title">Contact Me</h1>
                    <div className="row contact-me-row">
                <form className="contact-me-form" onSubmit={this.sendEmail}>
                    <div className="form-group">
                    <label for="exampleFormControlInput1" className="contact-me-label">Email address</label>
                    <input type="email" className="form-control" name="from_name" id="exampleFormControlInput1" placeholder="name@example.com" required/>
                    </div>
                    <br/>
                    <div className="form-group">
                        <label for="exampleFormControlInput1" className="contact-me-label">Subject</label>
                        <input type="text" className="form-control" name="subject" id="exampleFormControlInput1" placeholder="Your subject here" required/>
                    </div>
                    <br/>

                    <div className="form-group">
                    <label for="exampleFormControlTextarea1" className="contact-me-label">Message</label>
                    <textarea className="form-control form-control-message-area" name="message" id="exampleFormControlTextarea1" rows="3" required></textarea>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <button className="btn btn-lg hero-button send-message-button"  type="submit" value="Send"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                    </svg> Send Message</button>
                    <Recaptcha
                        sitekey="6Ld6U1EdAAAAAAxfEhH_e6iFQgXGjkdegoGa0K5x"
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded}
                        verifyCallback={this.verifyCallback}
                    />
                    <br/>
                    <div id="success-notice"></div>
                </form>
                </div>
                </div>
            </section>
            </div>
        )
    }}


export default Contact;