import React from "react";
import '../styles/style.css';

class Footer extends React.Component{
    render(){
        return(
            <div>
                <footer id="footer">
            <p className="footer-p">© Copyright <span id="curYr"></span> Daryle Urrea</p>
        </footer>
            </div>
        )
    }
}

export default Footer;