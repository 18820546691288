import React from 'react';
import About from './about';
import Skills from './skills';
import Projects from './projects';
import Contact from './contact';
import Footer from './footer';
import '../styles/style.css';


class Home extends React.Component {
    render() {
        return (
            <div>
                                                               
        <section id="hero-section">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-dark">
           
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="#hero-section">Home <span className="sr-only">(current)</span></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#aboutme-section">About</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#skills-section">Skills</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#section-portfolio">Projects</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#">Notes</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="#section-contact-me">Contact</a>
                    </li>
                  </ul>
                </div>
              </nav>
                    <div className = "row hero-row">
                        <div className="col-lg-6 col-sm-12">
                            <h1 className="title">Daryle Urrea</h1>                  
                            <span className="title-2 first-word">Software</span>
                            <span className="title-2 colored-word">Engineer</span>
                            <p className="p-title">Welcome to my site! I am a software engineer with an infinite interest in tech & software. I love coffee, traveling, dogs, and fitness.</p>
                            <button className="btn btn-lg hero-button download-button" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                            </svg> <a href="./images/resume_urrea.pdf" target="_blank">Resume</a></button>
                            <button className="btn btn-lg hero-button contactme-button" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                            </svg><a href="#section-contact-me"> Contact Me</a></button>
                        </div> 
                        <div className="col-lg-6 col-sm-12">
                            <img className="hero-image" src="./images/hero1.png" alt="swen-flat-design"/>
                        </div>              
                    </div>
                </div>
        </section>
                <About />
                <Skills />
                <Projects />
                <Contact />
                <Footer />

            </div>
        )
    }
}

export default Home;
