import React from 'react';
import '../styles/style.css';

class About extends React.Component {
    render(){
        return(
            <div>
            
                 <section id="aboutme-section">
            <div className="container-fluid">
                <div className= "container-card">
                    <div className="row about-row">
                        <div className ="about-left col-lg-6 col-sm-12">
                            <div >
                                <h1 className="about-left-title">About Me</h1>
                            </div>
                            <div className="about-left-info">
                                <p>
                                <br/><span className="info-field">Name:</span> Daryle Urrea
                                <br/><span className="info-field">Email:</span> daryle.urrea@hotmail.com
                                <br/><span className="info-field">Hometown:</span> El Monte California
                                </p>
                            </div>
                        </div>
                        <div className="about-right col-lg-6 col-sm-12">
                            <img className="about-image mx-auto d-block" src="./images/darylelinkedin2021_v2.png" alt=""/>
                        </div>

                    </div>
                        <div className="row">
                        <div className="about-desc">
                            <p>Hello! I'm Daryle, a US Navy veteran now living with my family in Brazil. My educational background: 1. Master's in Software Engineer (University of Maryland Global Campus 2022), 2. Bachelor's in Software Development and Security (University of Maryland Global Campus 2019), 3. Associate's in Graphic Design (The Art Institue of Pittsburgh 2012). While serving in the US military, traveling and experiencing different cultures are what I enjoyed the most. The beauty of Software Engineering lies in the ability to solve problems and to better lives.
</p>
                        </div>
                        </div>
                </div>
            </div>
            
        </section>
            </div>
        )
    }
}

export default About;